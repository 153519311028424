@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap");
/*@import url("https://fonts.googleapis.com/css2?family=Gloria+Hallelujah");
@import url("https://fonts.googleapis.com/css2?family=Petrona:wght@400");*/

body {
	font-size: 17px;
	/*background-color: #282c34;
  min-height: 100vh;
  color: white;*/
	line-height: 1.6;
color:#000;
}
body::after,
body::before,
input::after,
input::before,
select::after,
select::before,
textarea::after,
textarea::before,
body *::after,
body *::before {
	box-sizing: border-box;
}
.rel {
	position: relative;
}
#root {
width: 100%;
overflow: hidden;
}
.main {
	max-width: 700px;
	margin: 0 auto;
	padding: 0 4%;
	overflow: hidden;
}
.header_background+.header_background {
	max-width: 700px;
	margin: 0 auto;
	padding: 0;
	overflow: hidden;
	height: auto;
}
h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
h3, h4 {
    display: block;
    font-size: 1.4em;
    -webkit-margin-before: 1em;
    margin-block-start: 1em;
    -webkit-margin-after: 1em;
    margin-block-end: 1em;
    -webkit-margin-start: 0;
    margin-inline-start: 0;
    -webkit-margin-end: 0;
    margin-inline-end: 0;
    font-weight: 700;
}
.h1 {
	font-family: "Josefin Sans";
	font-size: 36px;
	display: inline-block;
	position: relative;
font-synthesis: none;
font-weight: 300;
}
.h1_main {
	font-family: "Josefin Sans";
	font-weight: 300;
	display: inline-block;
	position: relative;
	font-size: 28px;
font-synthesis: none;
}
.h1 strong {
	display: inline-block;
	position: relative;
}
.h1 strong::after {
	content: "";
	position: absolute;
	left: -2%;
	top: 0;
	height: 100%;
	margin-bottom: 10px;
	width: 0;
	width: 0;
	background: #111;
	transition: all 0.25s ease-in-out;
	z-index: -1;
	transform: rotate(1deg);
}
.h1.active strong {
	color: #fff;
}
.h1.active strong::after {
	transform: rotate(-1deg);
	width: 104%;
}
a.nolink {
	text-decoration: none;
	color: #000;
}
.fakelink {
	text-decoration: underline;
	cursor: pointer;
}
.h2 {
	font-family: "Gloria Hallelujah", cursive;
	font-size: 22px;
	margin-top: 15px;
	margin-bottom: 12px;
}
img {
	width: 100%;
	height: auto;
}
.code {
	background: rgb(40, 44, 52);
	color: rgb(255, 255, 255);
	border-radius: 10px;
	overflow: auto;
	tab-size: 1.5em;
}

.App {
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}
.header_background {
	position: relative;
	width: 100%;
	height: 250px;
	overflow: hidden;
}
.header_background img {
	width: 100%;
	object-fit: cover;
    	height: 100%;
	object-position: center 75%;
}
.header_background nav {
	position: relative;
}
.opa1 {
white-space: pre-wrap;
    word-wrap: break-word;
}
.margint20 {
margin-top: 20px;
}
.margint30 {
margin-top: 30px;
}
h3, h4 {
    display: block;
    font-size: 1.4em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
.author {
margin-top: -1em;
margin-bottom: 40px;
font-style:italic;
font-size: 14px;
}
.social {
margin-top: 15px;
display: flex;
justify-content: space-around;
width: 100%;
max-width: 480px;
}
.site-header {
display: none;
    background: #fff;
    width: 100%;
    position: relative;
top:0px;
margin-top: 0px;
left:0;
}
.site-header.bas {
display: block;
}
.site-header-i {
margin: 60px auto 0;
width: 100%;
max-width: 992px;
display: flex;
align-items: center;
justify-content: space-around;
flex-direction: column;
height: auto;
}
.site-header a {
text-transform: uppercase;
height: 60px;
font-weight: 500;
}
.main > nav > a {
text-transform: uppercase;
height: 60px;
font-weight: 500;
}

.pagination ul {
margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  padding: 0 0px;
}
  .pagination li a {
    border-radius: 7px;
    padding: 0.1rem 0.5rem;
    border: gray 1px solid;
    cursor: pointer;
	margin: 0 3px;
	text-decoration: none;
  }
  .pagination li.previous a,
  .pagination li.next a,
  .pagination li.break a {
    border-color: transparent;
  }
  .pagination li.active a {
    background-color: #0366d6;
    border-color: transparent;
    color: white;
  }
  .pagination li.disabled a {
    color: grey;
  }
  .pagination li.disable,
  .pagination li.disabled a {
    cursor: default;
  }
form > img {
display: none;
}
a.btn-amz {
display: block;
margin: 0 auto 40px;
font-weight: 700;
background: #FFD814;
border-color: #FCD200;
border-radius: 8px;
padding: 5px 10px;
width: 400px;
text-align: center;
font-size: 24px;
text-decoration: none;
}
.content img {
max-width: 400px;
margin: 0 auto;
display: block;
}
@media (min-width:360px) {

.pagination li a {
    padding: 0.1rem 0.6rem;
	margin: 0 3px;
  }
}
@media (min-width:400px) {

.pagination li a {
    padding: 0.1rem 0.7rem;
  }
}
@media (min-width:768px) {
.pagination li a {
    padding: 0.15rem 1rem;
  }
.header_background {
    height: 400px;
}
.site-header {
display: block;
background: #111;
}
.site-header a {
color:#fff;
}
.site-header.bas {
display: none;
}
.App > .main > .site-header-i {
display: none;
}
.site-header-i {
margin: 0 auto;
width: 100%;
max-width: 992px;
flex-direction: row;
height: 54px;
}
.site-header a {
height: auto;
}
body {
	font-size: 17px;
}
}
@media (min-width:992px) {
.h1 {
	font-size: 56px;
}
/*.react-parallax-bgimage {
	top:-25%;
}*/
.h1_main {
	font-size: 48px;
}
.header_background {
    height: 470px;
}
.main {
	padding: 0;
}
.header_background+.header_background {
	padding: 0;
}
}
@media (min-width:1099px) {
.header_background {
    height: 550px;
}
}
@media (min-width:1299px) {
.header_background {
    height: 650px;
}
}
/*
@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}*/
/*
#root {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}*/

.App-link {
	color: #61dafb;
}
/*
@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}*/
/*
pre {
	color: rgb(0, 0, 0);
	background: rgb(248, 248, 255) none repeat scroll 0% 0%;
	font-family: Inconsolata, Monaco, Consolas, "Courier New", Courier, monospace;
	direction: ltr;
	text-align: left;
	white-space: pre;
	word-spacing: normal;
	word-break: normal;
	line-height: 1.5;
	tab-size: 4;
	hyphens: none;
	padding: 1em;
	margin: 0.5em 0px;
	overflow: auto;
	border-radius: 0.3em;
}*/

/*code {
	font-family: Inconsolata, Monaco, Consolas, "Courier New", Courier, monospace;
	direction: ltr;
	text-align: left;
	white-space: pre-wrap;
	word-spacing: normal;
	word-break: normal;
	line-height: 1.5;
	tab-size: 4;
	hyphens: none;
}*/
.basdusite {
margin: 40px auto;
}
.basdusite-bas {
margin: 65px auto;
}
nav {
margin: 60px 0 20px;
}
nav ul {
	list-style: none;
	display: flex;
	justify-content: space-between;
}
nav li a {
	color:#000;
}

@media (max-width:359px) {
.h1 {
    font-size: 24px;
}
.h1_main {
    font-size: 20px;
}
}